'use client';

import { useEffect } from 'react';
import { isEmpty } from 'validator';
import { usePathname, useParams } from 'next/navigation';

import {
  PageParams,
  useTranslation_Client,
  DiagnodeApplicationRoutes,
} from '@preventx/diagnode-services';
import { SiteHeader_ExpandedHeading_Key } from '@preventx/diagnode-services/src/constants/configuration';

/** **Purpose**: Remove dynamic values (e.g. Language) from the URL and update any ID values
 * to the base slug value in order to determine which page heading we need to embed. This
 * component is far from perfect and will likely need further thought in the future. */
export const HeadingManager = () => {
  const pathname = usePathname();
  const params = useParams<PageParams>();

  const { t: translate } = useTranslation_Client(params.lang);

  useEffect(() => {
    const expandedElement = document.getElementById(
      SiteHeader_ExpandedHeading_Key,
    );

    const collapsedElement = document.getElementById(
      SiteHeader_ExpandedHeading_Key,
    );

    if (!expandedElement || !collapsedElement) {
      return;
    }

    const path = pathname
      .split('/')
      .filter((value) => value !== params.lang)
      .map((value) => (value === params.id ? ':id' : value))
      .join('/') as DiagnodeApplicationRoutes;

    const isPathEmpty = isEmpty(path);

    const response = {
      '/visit-checklist': translate(
        'diagnode-applications.visit-checklist.title',
      ),
      '/kit-activation': translate(
        'diagnode-applications.kit-activation.kit-activation-title',
      ),

      // Diagnode Headings
      '/': translate('diagnode-dashboard.title'),
      '/settings': translate('diagnode-settings.title'),
      '/settings/service-provider': translate(
        'diagnode-service-provider.initial-selection.title',
      ),

      // Service User Headings
      '/service-users': translate(
        'diagnode-applications.service-user.view-service-users-title',
      ),
      '/service-users/create-service-user': translate(
        'diagnode-applications.service-user.create-service-user-title',
      ),
      '/service-users/:id': translate(
        'diagnode-applications.service-user.service-user-details-title',
      ),

      // Orders Headings
      '/orders': translate('diagnode-applications.orders.view-orders-title'),
      '/orders/create-order': translate(
        'diagnode-applications.orders.create-order-title',
      ),
      '/orders/:id': translate(
        'diagnode-applications.orders.order-details-title',
      ),

      // Dispatch Headings
      '/dispatch': translate(
        'diagnode-applications.dispatch.view-orders-title',
      ),
      '/dispatch/:id': translate(
        'diagnode-applications.dispatch.order-details-title',
      ),

      // Screen Headings
      '/screenings': translate(
        'diagnode-applications.screen-viewer.view-screens-title',
      ),
      '/screenings/:id': translate(
        'diagnode-applications.screen-viewer.screen-details-title',
      ),

      // Platform User Headings
      '/platform-user/create-user': translate(
        'diagnode-applications.platform-user.create-platform-user-title',
      ),
    }[!isPathEmpty ? path : '/'];

    const title = response ?? 'Title Undefined';

    expandedElement.innerText = title;
    collapsedElement.innerText = title;
  }, [pathname]);

  return null;
};
